<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="content__header sub-page">
                <div class="content__header-left">
                    <router-link to="/products" class="btn btn-w-shadow btn-sm btn-back">
                        <inline-svg :src="require('@/assets/img/Arrow-prev.svg')" />
                    </router-link>
                    <div class="label-icon purple">
                        <img src="@/assets/img/grapes.svg" />
                    </div>
                    <div class="heading-wrapper">
                        <h1 class="heading-page-h1">{{ current.title }}</h1>
                        <ul class="heading-list">
                            <li class="active">
                                <inline-svg :src="require('@/assets/img/checkmark-circle.svg')" />
                                {{ current.status }}
                            </li>
                            <li v-if="current.category">
                                {{ currentCategory }}
                            </li>
                            <li>{{ current.quantity }} {{ current.units }}</li>
                            
                        </ul>
                    </div>
                </div>
                <div class="content__header-right">
                    <button type="button" class="btn btn-w-shadow btn-standart" @click="openQrCode('code1')">
						<inline-svg :src="require('@/assets/img/ico-qr-code.svg')"/>
						<span>{{ $translate('qr-code') }}</span>
					</button>
                    <router-link :to="`/products/edit/${productID}`" v-if="current.status!=='received'" class="btn btn-primary btn-standart">
                        <inline-svg :src="require('@/assets/img/ico-gear.svg')" />
                        <span>{{ $translate('edit') }}</span>
                    </router-link>
                </div>
            </div>
            <div class="tabs-wrapper">
                <ul class="tabs">
                    <li class="tabs__item">     
                        <a class="nav-tab" @click="switchTab('info')" :class="isActiveTab('info')"> {{ $translate('basic-info') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a class="nav-tab" @click="switchTab('manufacture-info')" :class="isActiveTab('manufacture-info')"> {{ $translate('manufacture-info') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a class="nav-tab" @click="switchTab('location')" :class="isActiveTab('location')"> {{ $translate('location') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a class="nav-tab" @click="switchTab('device')" :class="isActiveTab('device')"> {{ $translate('assign-device') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a class="nav-tab" @click="switchTab('history')" :class="isActiveTab('history')"> {{ $translate('transaction-history') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a class="nav-tab" @click="switchTab('parent')" :class="isActiveTab('parent')"> {{ $translate('parent-products') }} </a>
                    </li>
					<li class="tabs__item">
						<a data-tab="farm" class="nav-tab" @click="switchTab('farm')" :class="isActiveTab('farm')">
							{{ $translate('farm') }}
						</a>
					</li>
					<li class="tabs__item">
						<a data-tab="code-settings" class="nav-tab" @click="switchTab('code-settings')" :class="isActiveTab('code-settings')">
							{{ $translate('qr-code-settings') }}
						</a>
					</li>
                </ul>
            </div>
            <div class="content__body">
                <div class="tab-content">
                    <div id="info" class="tab-content__item" :class="isActiveTab('info')">
                        <div class="tab-content-wrapper">
                            <div class="company-page__info">
                                <ul>
                                    <li>
                                        <span> {{ $translate('product-name') }} </span>
                                        <p>
                                            {{ current.title }}
                                        </p>
                                    </li>
                                    <li>
                                        <span> {{ $translate('product-category') }} </span>
                                        <p>
                                            {{ currentCategory }}
                                        </p>
                                    </li>
                                    <li>
                                        <span> {{ $translate('quantity') }} </span>
                                        <p>
                                            {{ current.quantity }}
                                        </p>
                                    </li>
                                    <li>
                                        <span> {{ $translate('units') }} </span>
                                        <p>
                                            {{ current.units }}
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div class="company-page__info">
                                <span>{{ $translate('description') }}</span>
                                <p>
                                    {{ current.description }}
                                </p>
                            </div>
                            <div class="company-page__info">
                                <span>{{ $translate('media') }}</span>
                                <div class="gallery" v-if="media.length" style="padding: 0;">
                                    <div class="gallery__item" v-for="m in media" :key="m.id">
                                        <img :src="m.url">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="manufacture-info" class="tab-content__item" :class="isActiveTab('manufacture-info')">
                        <div class="tab-content-wrapper">
                            <div class="company-page__info">
                                <ul>
                                    <li>
                                        <span> {{ $translate('serial-number') }} </span>
                                        <p>
                                            {{ current.serial_number }}
                                        </p>
                                    </li>
                                    <li>
                                        <span> {{ $translate('manufacturing-date') }} </span>
                                        <p>
                                            {{ current.manufacturing_date }}
                                        </p>
                                    </li>
                                    <li>
                                        <span> {{ $translate('invoice-number') }} </span>
                                        <p>
                                            {{ current.invoice_number }}
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div class="company-page__info">
                                <ul>
                                    <li>
                                        <span> {{ $translate('purchase-order') }} </span>
                                        <p>
                                            {{ current.purchase_order }}
                                        </p>
                                    </li>
                                    <li>
                                        <span> {{ $translate('rfid-number') }} </span>
                                        <p>
                                            {{ current.rfid_number }}
                                        </p>
                                    </li>
                                    <li>
                                        <span> {{ $translate('batch-id') }} </span>
                                        <p>
                                            {{ current.batch_id }}
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div id="location" class="tab-content__item" :class="isActiveTab('location')">
                        <div class="tab-content__block">
                            <!-- <div class="map" style="border-radius: var(--border-radius-7); overflow: hidden" v-if="current.location">
                                <img src="@/assets/img/map-big.jpg" style="width: 100%" />
                            </div> -->
                            <div class="map" style="border-bottom-left-radius: var(--border-radius-7);border-bottom-right-radius: var(--border-radius-7); overflow: hidden;">
                                <!-- <input type="text" style="z-index:2"
                                    placeholder="USA, New York, New York" 
                                    class="input bg-left search-icon"
                                    name="location"
                                    
                                > -->
                                <div class="headline headline--sm flex-auto" style="padding-top: 10px;">
                                    <h2 class="headline__title">Address: {{ current.location.address }}</h2>
                                </div>
                                <div id="product-map" style="width: 100%; height: calc(100vh - 270px);"></div>
                            </div>



                            <!-- <p v-else>No Location yet</p> -->
                        </div>
                    </div>
                    <div id="device" class="tab-content__item" :class="isActiveTab('device')">
                        <div class="addition">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">{{ $translate('assign-devices') }}</h2>
                                    <p class="headline__text">{{ $translate('here-you-can-find-devices-assigned-to-your-prouct') }}</p>
                                </div>
                            </div>
                            <ul class="editable-list">
                                <li class="editable-list__item" v-for="d in devices" :key="d.id"> 
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">{{d.name}}</li>
                                            <li class="data-row__item">
                                                <DeviceIcon :category="d.category"></DeviceIcon>
                                                {{d.category}}
                                            </li>
                                            <li class="data-row__item">{{d.location||"none"}}</li>
                                        </ul>
                                       
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div id="history" class="tab-content__item" :class="isActiveTab('history')">
                        <div class="table-wrapper">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="table-filter">
                                                <div class="table-filter__options">
                                                    <button class="table-filter__btn">
                                                        <span> {{ $translate('product-id') }} </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="table-filter">
                                                <div class="table-filter__options">
                                                    <button class="table-filter__btn">
                                                        <span> {{ $translate('type') }} </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="table-filter">
                                                <div class="table-filter__options">
                                                    <button class="table-filter__btn">
                                                        <span> {{ $translate('date-/-time') }} </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="table-filter">
                                                <div class="table-filter__options">
                                                    <button class="table-filter__btn">
                                                        <span> {{ $translate('event') }} </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="table-filter">
                                                <div class="table-filter__options">
                                                    <button class="table-filter__btn">
                                                        <span> {{ $translate('company') }} </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="table-filter">
                                                <div class="table-filter__options">
                                                    <button class="table-filter__btn">
                                                        <span> {{ $translate('rfid-number') }} </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>123456789</td>
                                        <td>Receive Dispatch</td>
                                        <td>02.02.2021, 12:00</td>
                                        <td>Custom description for transaction...</td>
                                        <td>Company Name 1</td>
                                        <td>E0040100019D0CA1</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div id="parent" class="tab-content__item" :class="isActiveTab('parent')">
                        <div class="addition">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">{{ $translate('parent-products') }}</h2>
                                    <!-- <p class="headline__text">{{ $translate('here-you-can-find-devices-assigned-to-your-prouct') }}</p> -->
                                    <p class="headline__text">Here you can find parent products assigned to your product</p>
                                </div>
                            </div>
                            <ul class="editable-list">
                                <li class="editable-list__item" v-for="p in current.parent_products" :key="p.id">
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">Name: {{getProductName(p.id)}}</li>
                                            <li class="data-row__item">Type of event: {{eventTypes[p.type] || p.type}}</li>
                                            <li v-if="getProductFullObject(p.id).quantity">
                                                Quantity: <span style="text-transform:uppercase;">{{ getProductFullObject(p.id).quantity || '0' }} {{ getProductFullObject(p.id).units }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                           
                        </div>
                    </div>
                    <div id="farm" class="tab-content__item" :class="isActiveTab('farm')">
                        <div class="addition footer-fixed">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">{{ $translate('farm') }}</h2>
                                    <p class="headline__text">{{ $translate('here-you-can-manage-farm-items-linked-to-your-product') }}</p>
                                </div>
                            </div>
                            <ul class="editable-list">
                                <template v-if="selectedPaddocks.length">
                                    <li class="editable-list__item" v-for="paddockItem in selectedPaddocks" :key="paddockItem.id">
                                        <div class="data-row">
                                            <ul class="data-row__list">
                                                <li class="data-row__item">Field name: {{ paddockItem.basic_info.name }}</li>
                                                <li class="data-row__item" style="text-transform: capitalize;">
                                                    Total area: {{ 
                                                        paddockItem.type !== 'herd' && paddockItem.basic_info.total_area ? paddockItem.basic_info.total_area : 
                                                        paddockItem.type === 'herd' ? paddockItem.basic_info.type : paddockItem.type
                                                    }}
                                                </li>
                                                <li class="data-row__item" style="text-transform: capitalize;" v-if="paddockItem.basic_info.plots_config && paddockItem.basic_info.plots_config.crop">
                                                    Crop: {{ paddockItem.basic_info.plots_config.crop }}
                                                </li>
                                                <li class="data-row__item" style="text-transform: capitalize;" v-if="paddockItem.basic_info.plots_config && paddockItem.basic_info.plots_config.variety">
                                                    Variety: {{ paddockItem.basic_info.plots_config.variety }}
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </template>
                                <li v-if="!selectedPaddocks.length">
                                    <p>{{ $translate('farm-items-not-linked') }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div id="code-settings" class="tab-content__item" :class="isActiveTab('code-settings')">
                        <div class="wizard-item footer-fixed">
                            <div class="headline headline--sm mt-15">
                                <h2 class="headline__title">{{ $translate('company-data') }}</h2>
                                <p class="headline__text">{{ $translate('select-info-that-you-want-to-show-in-qr-code') }}</p>
                            </div>
                            <div class="cards flex">
                                <div class="cards__col-4 cards__col-md-6 mt-15 flex flex-col" v-for="colNumber in [0,1,2]" :key="colNumber" >
                                    <label class="module__check disabled" v-for="(inputKey, index) in getCell(QRSettings.companyData,colNumber)" :key="inputKey" :class="index>0?'mt-15':null">
                                        <input type="checkbox" name="privacy_policy" v-model="QRSettings.companyData[inputKey]">
                                        <span class="check"></span>
                                        <span class="text" >
                                            <span class="input-text" >
                                                {{inputKey.replaceAll("_"," ")}}
                                            </span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div class="cards items-group border-top mt-15">
                                <div class="cards__col">
                                    <div class="headline-wrapper flex items-center">
                                        <div class="headline headline--sm flex-auto mt-15">
                                            <h2 class="headline__title">{{ $translate('iot-date') }}</h2>
                                            <p class="headline__text">{{ $translate('select-info-that-you-want-to-show-in-qr-code') }}</p>
                                        </div>
                                    </div>
                                    <ul class="editable-list">
                                        <li class="editable-list__item" v-for="d in devices" :key="d.id">
                                            <div class="data-row flex-col has-spoiler">
                                                <div class="data-row__head flex w-full">
                                                    <ul class="data-row__list">
                                                        <li class="data-row__item">{{d.name}}</li>
                                                        <!-- <li class="data-row__item">
                                                            <span class="data-row__icon"><DeviceIcon :category="d.category"></DeviceIcon></span> 
                                                            {{d.category}}
                                                        </li> -->
                                                        <li class="data-row__item" v-if="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id) && QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObeservedOptions">
                                                            <span class="data-row__icon" v-if="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved">
                                                                <DeviceIcon :category="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved" />
                                                            </span> 
                                                            {{ devCats.find(itemF => itemF.id === QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved).name }}
                                                        </li>
                                                        <li class="data-row__item">{{d.location}}</li>
                                                    </ul>
                                                </div>
                                                <div class="data-row__spoiler spoiler" v-if="QRSettings.deviceData.length && QRSettings.deviceData.map(itemM => itemM.deviceID).includes(d.id)">
                                                    <div class="spoiler__button flex items-center" @click="opedOptions">
                                                        <span class="flex-auto pointer-events-none">{{ $translate('device-info') }}</span>
                                                        <inline-svg class="pointer-events-none" :src="require(`@/assets/img/Arrow-down.svg`)"/>
                                                    </div>
                                                    <div class="spoiler__body flex flex-col">
                                                        <label class="module__check disabled">
                                                            <input type="checkbox" name="privacy_policy" v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).atDayCreation">
                                                            <span class="check"></span>
                                                            <span class="text">{{ devCats.find(itemF => itemF.id === QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved).name }} {{ $translate('at-the-day-of-product-creation') }}</span>
                                                        </label>
                                                        <label class="module__check disabled mt-15">
                                                            <input type="checkbox" name="privacy_policy" v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).duringDayCreation">
                                                            <span class="check"></span>
                                                            <span class="text">
                                                                {{ devCats.find(itemF => itemF.id === QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved).name }} {{ $translate('during-the-day/week/month-of-the-product-creation') }}
                                                            </span>
                                                        </label>
                                                        <label class="module__check disabled mt-15">
                                                            <input type="checkbox" name="privacy_policy" v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).duringSmartCondition">
                                                            <span class="check"></span>
                                                            <span class="text">
                                                                {{ devCats.find(itemF => itemF.id === QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved).name }} {{ $translate('during-the-smart-condition-in-the-dispatch') }}
                                                            </span>
                                                        </label>
                                                        <label class="module__check disabled mt-15">
                                                            <input type="checkbox" name="privacy_policy" v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).maxMinValue.checked">
                                                            <span class="check"></span>
                                                            <span class="text">
                                                                {{ $translate('max/min-value-of-the') }} <span style="text-transform: lowercase;margin: 0 5px;">
                                                                    {{ devCats.find(itemF => itemF.id === QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved).name }}
                                                                </span> {{ $translate('during-a-time-range') }}
                                                            </span>
                                                        </label>
                                                        <div class="cards flex" style="max-width: 630px;">
                                                            <div class="cards__col-6 mt-15">
                                                                <div class="buttons-group">
                                                                    <DatePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).maxMinValue.minDate" class="disabled" />
                                                                    <TimePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).maxMinValue.minTime" class="disabled" />
                                                                </div>
                                                            </div>
                                                            <div class="cards__col-6 mt-15">
                                                                <div class="buttons-group">
                                                                    <DatePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).maxMinValue.maxDate" class="disabled" />
                                                                    <TimePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).maxMinValue.maxTime" class="disabled" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <label class="module__check disabled mt-15">
                                                            <input type="checkbox" name="privacy_policy" v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).exactValue.checked" exactValue>
                                                            <span class="check"></span>
                                                            <span class="text">
                                                                {{ $translate('exact-value-of-the') }} <span style="text-transform: lowercase;margin: 0 5px;">
                                                                    {{ devCats.find(itemF => itemF.id === QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).parameterObserved).name }}
                                                                </span> {{ $translate('during-a-time-range') }}
                                                            </span>
                                                        </label>
                                                        <div class="cards flex" style="max-width: 630px;">
                                                            <div class="cards__col-6 mt-15">
                                                                <div class="buttons-group">
                                                                    <DatePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).exactValue.firstDate" class="disabled" />
                                                                    <TimePicker v-model="QRSettings.deviceData.find(itemM => itemM.deviceID === d.id).exactValue.firstTime" class="disabled" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="cards items-group border-top mt-15">
                                <div class="cards__col">
                                    <div class="headline headline--sm mt-15">
                                        <h2 class="headline__title">{{ $translate('product-chain-data') }}</h2>
                                        <p class="headline__text">{{ $translate('select-info-that-you-want-to-show-in-qr-code') }}</p>
                                    </div>
                                    <div class="cards flex">
                                        <template v-for="inputKey in Object.entries(QRSettings.productData)">
                                            <div 
                                                class="cards__col-4 cards__col-md-6" :key="inputKey[0]"
                                                v-if="inputKey[0] !== 'transaction_history' && inputKey[0] !== 'device_rule_log'"
                                            >
                                                <label class="module__check disabled mt-15">
                                                    <input type="checkbox" name="privacy_policy" v-model="QRSettings.productData[inputKey[0]]">
                                                    <span class="check"></span>
                                                    <span class="text" >
                                                        <span class="input-text" >
                                                            {{inputKey[0].replaceAll("_"," ")}}
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div class="cards items-group border-top mt-15">
                                <div class="cards__col">
                                    <div class="cards flex">
                                        <div class="cards__col-4 cards__col-md-6">
                                            <label class="module__check disabled mt-15">
                                                <input type="checkbox" v-model="actions_info">
                                                <span class="check"></span>
                                                <span class="text">
                                                    <span class="input-text">{{ $translate('actions-info') }}</span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <QrCode 
            v-if="qrCode.show" 
            link="http://localhost:8080/qr-mobile"  
            :qrCode="qrCode.code" 
            :product="current"
            @close="closeQrCode"
        ></QrCode>
    </div>
</template>

<script>
import Base from "@/components/base";
import Popups from "@/components/popups";
import {getProduct} from "@/graphql/queries"
import { mixTabs,mixQrCode } from '@/mixins';
import {eventTypes} from "@/config/products";
import { Storage } from 'aws-amplify';
import { devCats } from '@/config';

export default {
    props: ["productID"],
    name: "SingleProduct",
    components: {
        ...Popups,
        ...Base
    },
    mixins: [mixTabs,mixQrCode],
    data() {
        return {
            pageTabs:['info','manufacture-info', 'location', 'device', 'history', 'parent', 'farm', 'code-settings'],
            QRSettings:{
				companyData:{
					location:false,
					history_of_company:false,
					media:false,
					documentation:false,
					year_of_foundation:false,
					production_per_year:false,
					production_area:false,
					soils:false,
					cellar_work:false,
					productivity:false,
					certifications:false,
					philosophy:false,
				},
				deviceData:[],
				productData:{
					datetime_creation: false ,
					rules_business_conditions: false,
					datetime_dispatch: false,
					datetime_receive: false,
					datetime_return: false,
					transaction_history: true,
					device_rule_log: true,
					basic_info: false,
					parent_products: false,
					list_of_dispatches: false,
					linked_IOT_devices: false,
                    smart_conditions: false
				}
			},
            actions_info: false,
            media: [],
            devCats: devCats
        };
    },
    computed: {
        products() {
            return this.$store.state.products || [];
        },
        allProducts(){
			return [ ...this.$store.state.products, ...this.$store.state.receivedProducts ]
		},
        categories(){
            return this.$store.state.categories || []
        },
        current() {
            return this.allProducts.find((p) => p.id === this.productID) || {};
        },
        devices(){
            if(this.current.devices && this.current.devices.items){
                return  this.current.devices.items
            }else{
                return []
            }
        },
        selectedPaddocks(){
			return this.$store.state.paddocks.filter(item => this.current?.paddocks?.includes(item.id));
		},
        eventTypes(){
			return eventTypes
		},
        currentCategory(){
            // console.log('test',this.current.category.items[0]);
            if(this.current?.category?.items[0]){
                let findedItem = this.categories.find(c=>c.id==this.current.category.items[0].categoryID);
                if(findedItem) {
                    return findedItem.name;
                } else {
                    return '';
                }
            }
        },
        paddockList(){
            return this.$store.state.paddocks;
        },
    },

    async mounted() {
        // console.log("created single");
        if (this.products.length<1){
            await this.$store.dispatch("GET_PRODUCTS");
        };
		await this.initMap();
        if(this.current?.qr_settin?.devices){
            this.QRSettings.deviceData = this.current.qr_settings.devices.map(item => JSON.parse(item.config));
        }

        this.actions_info = this.current?.qr_settings?.actions_info;

        if(this.current.qr_settings){
            Object.keys(this.current.qr_settings).forEach(element => {
                Object.keys(this.QRSettings.companyData).forEach(element2 => {
                    if(element === element2) {
                        this.QRSettings.companyData[element2] = this.current.qr_settings[element];
                    }
                })
                Object.keys(this.QRSettings.productData).forEach(element2 => {
                    if(element === element2) {
                        this.QRSettings.productData[element2] = this.current.qr_settings[element];
                    }
                })
            });
        }

        this.getAttachments();
    },
	// async mounted(){
    //     console.log("mounted",this.products,this.$store.state.products);
	// },
    methods: {
        getProductName(id){
			return this.allProducts.find(p=>p.id==id)?.title
		},
        getProductFullObject(id){
			return this.allProducts.find(p=>p.id==id)
		},
        getCell(obj,colNumber){
			let keys = Object.keys(obj)
			let size=keys.length
			let fullColsCount=Math.floor(size/3);
			let lastColsCount=size-fullColsCount*3
			let rows=[]
			for(let i=0;i<fullColsCount;i++){
				rows.push(keys.splice(0,3))
			}
			if(lastColsCount){
				rows.push(keys.splice(0,lastColsCount))
			}
			let col=[]
			rows.forEach(r=>{
				if(r[colNumber]){
					col.push(r[colNumber])
				}
			})
			return col
		},
        opedOptions(ev) {
            ev.target.classList.toggle("active");
            ev.target.nextElementSibling.classList.toggle("active");
        },
        async initMap() {
            // console.log("initMap",this.current);
			let payload = {
				markerType: "product",
                notEditable: true,
                lat: this.current.location?.lat || null,
                lng: this.current.location?.lng || null,
			}

            let devicePaddok = null;

            if(this.current.paddocks && this.current.paddocks.length){
                devicePaddok = this.paddockList.find(item => item.id === this.current.paddocks[0]);
            }

            if(devicePaddok){
                if(devicePaddok.map_info.center_lat && devicePaddok.map_info.center_lng){
                    payload.lat = devicePaddok.map_info.center_lat;
                    payload.lng = devicePaddok.map_info.center_lng;
                }

                if(devicePaddok.map_info.points && devicePaddok.map_info.points.length){
                    let latValue = 0;
                    let lngValue = 0;

                    for (let index = 0; index < devicePaddok.map_info.points.length; index++) {
                        latValue += devicePaddok.map_info.points[index].lat;
                        lngValue += devicePaddok.map_info.points[index].lng;                        
                    }
                    latValue = latValue / devicePaddok.map_info.points.length;
                    lngValue = lngValue / devicePaddok.map_info.points.length;

                    payload.lat = latValue;
                    payload.lng = lngValue;
                }
            }

			this.$provider.gmap.createMap('product-map', payload, (res) => {
				this.setLocation(res)
				
			});
		},
        async getAttachments() {
			let requests;

			if(this.current && this.current.media && this.current.media.items) {
				requests = this.current.media.items.map(async item => { 
					return {
						id: item.id,
						key: item.key,
						url: await Storage.get(item.key) 
					}	
				});
				Promise.all(requests).then(responses => this.media = responses);
			}			
		},
        
        // async getProductInfo() {
        //     const product = await API.graphql({
        //         query: getProduct,
        //         variables: {
        //             id: this.id,
        //         },
        //     }).then((res) => res.data.getProduct);
        
        //     this.backup = product;
        // },
    },
};
</script>

<style></style>
